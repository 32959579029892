import React from 'react';

import Icon, { IconTile } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import FolderClosedIcon from '@atlaskit/icon/core/folder-closed';

const folderSvg = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2_2)">
			<path
				d="M17.7778 0H2.22222C0.994923 0 0 0.994923 0 2.22222V17.7778C0 19.0051 0.994923 20 2.22222 20H17.7778C19.0051 20 20 19.0051 20 17.7778V2.22222C20 0.994923 19.0051 0 17.7778 0Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17 15C17 16.1046 16.1045 17 15 17H4.99994C3.89537 17 2.99994 16.1046 2.99994 15V5.00001C2.99994 3.89544 3.89537 3.00001 4.99994 3.00001H8.49994L10.4999 5.00001H15C16.1045 5.00001 17 5.89544 17 7.00001V15ZM15.5 10H4.49994V15C4.49994 15.2761 4.72379 15.5 4.99994 15.5H15C15.2761 15.5 15.5 15.2761 15.5 15V10ZM15.5 8.50001H4.49994V5.00001C4.49994 4.72387 4.72379 4.50001 4.99994 4.50001H7.87862L9.43928 6.06068C9.72058 6.34198 10.1021 6.50001 10.4999 6.50001H15C15.2761 6.50001 15.5 6.72387 15.5 7.00001V8.50001Z"
				fill={token('color.text.inverse', '#FFFFFF')}
			/>
		</g>
	</svg>
);

export const Folder20Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.magenta', '#CD519D');
	const label = props.label || '';
	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="folder"
				label={label}
				appearance="magentaBold"
				shape="square"
				size="16"
				icon={FolderClosedIcon}
			/>
		);
	} else {
		return <Icon glyph={folderSvg} {...props} primaryColor={primaryColor} label={label} />;
	}
};
