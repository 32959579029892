import React from 'react';

import Icon, { IconTile } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';
import DatabaseIcon from '@atlaskit/icon/core/database';

const database16Svg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_326_275)">
			<rect width="16" height="16" fill={token('color.icon.inverse', N0)} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2ZM3 4C3 3.44772 3.44772 3 4 3H5C5.55228 3 6 3.44772 6 4C6 4.55228 5.55228 5 5 5H4C3.44772 5 3 4.55228 3 4ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H5C5.55228 9 6 8.55229 6 8C6 7.44772 5.55228 7 5 7H4ZM3 12C3 11.4477 3.44772 11 4 11H5C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13H4C3.44772 13 3 12.5523 3 12ZM8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5H12C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3H8ZM7 8C7 7.44772 7.44772 7 8 7H12C12.5523 7 13 7.44772 13 8C13 8.55229 12.5523 9 12 9H8C7.44772 9 7 8.55229 7 8ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H8Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_326_275">
				<rect width="16" height="16" fill={token('color.icon.inverse', N0)} />
			</clipPath>
		</defs>
	</svg>
);

export const Database16Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.purple', '#8270DB');
	const label = props.label || '';
	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="database"
				label={label}
				appearance="purpleBold"
				shape="square"
				size="16"
				icon={DatabaseIcon}
			/>
		);
	} else {
		return <Icon glyph={database16Svg} {...props} primaryColor={primaryColor} label={label} />;
	}
};
