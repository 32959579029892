import React from 'react';

import Icon, { IconTile } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import SmartLinkListIcon from '@atlaskit/icon/core/smart-link-list';
import { N0 } from '@atlaskit/theme/colors';
const database24Svg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
		<g clipPath="url(#clip0_2774_123018)">
			<rect
				width="24"
				height="24"
				transform="translate(0 0.5)"
				fill={token('color.icon.inverse', N0)}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H21C22.6569 0.5 24 1.84315 24 3.5V21.5C24 23.1569 22.6569 24.5 21 24.5H3C1.34315 24.5 0 23.1569 0 21.5V3.5ZM11 7C11 6.17157 11.6716 5.5 12.5 5.5H18.5C19.3284 5.5 20 6.17157 20 7C20 7.82843 19.3284 8.5 18.5 8.5H12.5C11.6716 8.5 11 7.82843 11 7ZM12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14H18.5C19.3284 14 20 13.3284 20 12.5C20 11.6716 19.3284 11 18.5 11H12.5ZM11 18C11 17.1716 11.6716 16.5 12.5 16.5H18.5C19.3284 16.5 20 17.1716 20 18C20 18.8284 19.3284 19.5 18.5 19.5H12.5C11.6716 19.5 11 18.8284 11 18ZM5.5 5.5C4.67157 5.5 4 6.17157 4 7C4 7.82843 4.67157 8.5 5.5 8.5H7.5C8.32843 8.5 9 7.82843 9 7C9 6.17157 8.32843 5.5 7.5 5.5H5.5ZM4 12.5C4 11.6716 4.67157 11 5.5 11H7.5C8.32843 11 9 11.6716 9 12.5C9 13.3284 8.32843 14 7.5 14H5.5C4.67157 14 4 13.3284 4 12.5ZM5.5 16.5C4.67157 16.5 4 17.1716 4 18C4 18.8284 4.67157 19.5 5.5 19.5H7.5C8.32843 19.5 9 18.8284 9 18C9 17.1716 8.32843 16.5 7.5 16.5H5.5Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2774_123018">
				<rect
					width="24"
					height="24"
					fill={token('color.icon.inverse', N0)}
					transform="translate(0 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export const Database24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.purple', '#8270DB');
	const label = props.label || '';
	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="database"
				label={label}
				appearance="purpleBold"
				shape="square"
				size="24"
				icon={SmartLinkListIcon}
			/>
		);
	} else {
		return <Icon glyph={database24Svg} {...props} primaryColor={primaryColor} label={label} />;
	}
};
