import React from 'react';

import Icon, { IconTile } from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import { fg } from '@atlaskit/platform-feature-flags';

const whiteboard16Svg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM6.76162 3.41159C7.86083 2.11703 9.46511 1.64478 10.6195 2.27467C10.7705 2.35706 10.9977 2.49509 11.1722 2.71987C11.373 2.97851 11.4727 3.31107 11.409 3.68304C11.3524 4.01358 11.1765 4.31922 10.9546 4.60153C10.8863 4.68851 10.8097 4.77814 10.7246 4.87072C11.674 4.60241 12.5151 4.65073 13.1473 5.09225C13.9681 5.6655 14.1134 6.66452 13.9141 7.50611C13.8187 7.90918 13.4146 8.15858 13.0115 8.06316C12.6084 7.96774 12.359 7.56363 12.4545 7.16056C12.5709 6.6688 12.4237 6.41651 12.2884 6.32204C12.1286 6.21042 11.6364 6.05866 10.5554 6.52127C10.3091 6.62667 10.0224 6.63652 9.81961 6.62447C9.59851 6.61132 9.32166 6.56256 9.07282 6.44016C8.84101 6.32614 8.43506 6.03646 8.43173 5.48745C8.42884 5.01198 8.74599 4.65814 9.01233 4.43019C9.3922 4.10508 9.63128 3.85787 9.77522 3.67471C9.8072 3.63401 9.83276 3.59864 9.85315 3.56837C9.50001 3.41882 8.68015 3.4696 7.90504 4.38247C7.63694 4.69821 7.16364 4.73684 6.84789 4.46874C6.53215 4.20064 6.49352 3.72734 6.76162 3.41159ZM4.83333 9.41667C5.56971 9.41667 6.16667 8.81971 6.16667 8.08333C6.16667 7.34695 5.56971 6.75 4.83333 6.75C4.09695 6.75 3.5 7.34695 3.5 8.08333C3.5 8.81971 4.09695 9.41667 4.83333 9.41667ZM4.83333 10.9167C6.39814 10.9167 7.66667 9.64814 7.66667 8.08333C7.66667 6.51853 6.39814 5.25 4.83333 5.25C3.26853 5.25 2 6.51853 2 8.08333C2 9.64814 3.26853 10.9167 4.83333 10.9167ZM9.14834 8.88394C9.53883 8.2494 10.4612 8.2494 10.8517 8.88394L13.0621 12.4759C13.4721 13.1422 12.9928 14 12.2104 14H7.78956C7.00724 14 6.52789 13.1422 6.9379 12.4759L9.14834 8.88394ZM8.68435 12.5L10 10.3621L11.3157 12.5H8.68435Z"
			fill="currentColor"
		/>
	</svg>
);

export const Whiteboard16Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.teal', '#00A3BF');
	const label = props.label || '';

	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="whiteboard"
				label={label}
				appearance="tealBold"
				shape="square"
				size="16"
				icon={WhiteboardIcon}
			/>
		);
	} else {
		return <Icon glyph={whiteboard16Svg} {...props} primaryColor={primaryColor} label={label} />;
	}
};
