import React from 'react';

import Icon, { IconTile } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';
import LinkIcon from '@atlaskit/icon/core/link';

const SmartLinkEmbed16Svg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="16" height="16" rx="3" fill="currentColor" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.57067 3.638L7.946 4.25133C7.88196 4.31359 7.83105 4.38805 7.79628 4.47033C7.76152 4.5526 7.7436 4.64101 7.7436 4.73033C7.7436 4.81965 7.76152 4.90806 7.79628 4.99033C7.83105 5.07261 7.88196 5.14708 7.946 5.20933C8.0764 5.33666 8.25142 5.40795 8.43367 5.40795C8.61592 5.40795 8.79095 5.33666 8.92134 5.20933L9.57734 4.56533C10.222 3.932 11.272 3.80866 11.9787 4.37333C12.1677 4.52241 12.3227 4.71006 12.4334 4.92377C12.5442 5.13749 12.6081 5.37236 12.6209 5.61272C12.6337 5.85308 12.5951 6.09341 12.5077 6.31768C12.4203 6.54196 12.2861 6.74502 12.114 6.91333L10.1787 8.81466C9.84918 9.1362 9.40705 9.31618 8.94667 9.31618C8.48629 9.31618 8.04416 9.1362 7.71467 8.81466L6.97467 8.088L6 9.04666L6.73867 9.77266C7.95334 10.9647 9.94 10.9647 11.1533 9.77266L13.09 7.87133C13.3971 7.56973 13.6367 7.20631 13.7928 6.80515C13.9489 6.40399 14.018 5.97424 13.9955 5.54437C13.973 5.1145 13.8594 4.69431 13.6623 4.31164C13.4651 3.92897 13.189 3.59255 12.852 3.32466C11.59 2.31333 9.722 2.50733 8.57067 3.638Z"
			fill={token('color.icon.inverse', N0)}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.42934 13.0287L8.054 12.4153C8.11805 12.3531 8.16896 12.2786 8.20373 12.1963C8.23849 12.1141 8.2564 12.0256 8.2564 11.9363C8.2564 11.847 8.23849 11.7586 8.20373 11.6763C8.16896 11.5941 8.11805 11.5196 8.054 11.4573C7.92364 11.3302 7.74876 11.259 7.56667 11.259C7.38458 11.259 7.2097 11.3302 7.07934 11.4573L6.42267 12.1013C5.778 12.7347 4.728 12.858 4.02134 12.2933C3.83236 12.1443 3.67733 11.9566 3.56659 11.7429C3.45585 11.5292 3.39195 11.2943 3.37914 11.0539C3.36633 10.8136 3.40492 10.5733 3.49232 10.349C3.57972 10.1247 3.71393 9.92164 3.886 9.75333L5.82134 7.852C6.15083 7.53046 6.59296 7.35048 7.05334 7.35048C7.51371 7.35048 7.95585 7.53046 8.28534 7.852L9.02534 8.57866L10 7.62L9.26134 6.894C8.04667 5.702 6.06 5.702 4.84667 6.894L2.91 8.79533C2.60286 9.09693 2.36331 9.46035 2.20721 9.86151C2.05111 10.2627 1.98202 10.6924 2.00452 11.1223C2.02702 11.5522 2.1406 11.9724 2.33773 12.355C2.53487 12.7377 2.81105 13.0741 3.148 13.342C4.41 14.3533 6.278 14.1593 7.42934 13.0287Z"
			fill={token('color.icon.inverse', N0)}
		/>
	</svg>
);

export const SmartLinkEmbed16Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.lime', '#82B536');
	const label = props.label || '';
	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="smartLinkEmbed"
				label={label}
				appearance="limeBold"
				shape="square"
				size="16"
				icon={LinkIcon}
			/>
		);
	} else {
		return (
			<Icon glyph={SmartLinkEmbed16Svg} {...props} primaryColor={primaryColor} label={label} />
		);
	}
};
