import React from 'react';

import Icon, { IconTile } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import FolderClosedIcon from '@atlaskit/icon/core/folder-closed';

const folderSvg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2_2)">
			<path
				d="M21.3333 0H2.66667C1.19391 0 0 1.19391 0 2.66667V21.3333C0 22.8061 1.19391 24 2.66667 24H21.3333C22.8061 24 24 22.8061 24 21.3333V2.66667C24 1.19391 22.8061 0 21.3333 0Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.4 18C20.4 19.3255 19.3255 20.4 18 20.4H5.99993C4.67445 20.4 3.59993 19.3255 3.59993 18V6.00001C3.59993 4.67453 4.67445 3.60001 5.99993 3.60001H10.1999L12.5999 6.00001H18C19.3255 6.00001 20.4 7.07453 20.4 8.40001V18ZM18.6 12H5.39993V18C5.39993 18.3313 5.66856 18.6 5.99993 18.6H18C18.3313 18.6 18.6 18.3313 18.6 18V12ZM18.6 10.2H5.39993V6.00001C5.39993 5.66864 5.66856 5.40001 5.99993 5.40001H9.45434L11.3271 7.27281C11.6647 7.61037 12.1225 7.80001 12.5999 7.80001H18C18.3313 7.80001 18.6 8.06864 18.6 8.40001V10.2Z"
				fill={token('color.text.inverse', '#FFFFFF')}
			/>
		</g>
	</svg>
);

export const Folder24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.magenta', '#CD519D');
	const label = props.label || '';
	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="folder"
				label={label}
				appearance="magentaBold"
				shape="square"
				size="24"
				icon={FolderClosedIcon}
			/>
		);
	} else {
		return <Icon glyph={folderSvg} {...props} primaryColor={primaryColor} label={label} />;
	}
};
