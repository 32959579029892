import React from 'react';

import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import Icon, { IconTile } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';

const whiteboard24Svg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM10.2366 5.10488C11.8822 3.16021 14.2555 2.48102 15.9361 3.40114C16.1615 3.52456 16.4861 3.72402 16.7331 4.04329C17.015 4.40753 17.1546 4.87379 17.065 5.39881C16.9847 5.86907 16.7333 6.3132 16.4024 6.73569C16.2029 6.99041 15.9549 7.26189 15.6547 7.55368C17.3048 6.96438 18.7399 6.97443 19.771 7.69697C20.9549 8.52664 21.1753 9.97951 20.8804 11.2296C20.7536 11.7672 20.215 12.1001 19.6775 11.9733C19.14 11.8465 18.807 11.3079 18.9338 10.7704C19.1194 9.98373 18.8948 9.52514 18.6232 9.33484C18.3205 9.12274 17.5125 8.91139 15.8504 9.62511C15.5049 9.77347 15.094 9.78969 14.7932 9.77174C14.4679 9.75233 14.0662 9.6804 13.7094 9.50431C13.3745 9.33899 12.8184 8.9342 12.8139 8.1794C12.8099 7.52231 13.2478 7.01975 13.6469 6.67705C14.229 6.17715 14.6006 5.79262 14.8278 5.50254C14.9312 5.37049 14.9972 5.26748 15.0382 5.19112C15.0201 5.1803 14.9993 5.16839 14.9756 5.15542C14.4091 4.84527 13.0283 4.902 11.7634 6.39679C11.4066 6.81839 10.7756 6.87096 10.354 6.51421C9.93245 6.15745 9.87988 5.52647 10.2366 5.10488ZM7.25 14.25C8.49264 14.25 9.5 13.2426 9.5 12C9.5 10.7574 8.49264 9.75 7.25 9.75C6.00736 9.75 5 10.7574 5 12C5 13.2426 6.00736 14.25 7.25 14.25ZM7.25 16.25C9.59721 16.25 11.5 14.3472 11.5 12C11.5 9.65279 9.59721 7.75 7.25 7.75C4.90279 7.75 3 9.65279 3 12C3 14.3472 4.90279 16.25 7.25 16.25ZM12.5324 19L15 14.8873L17.4676 19H12.5324ZM16.2862 13.1437L19.637 18.7282C20.2368 19.728 19.5167 21 18.3507 21L11.6493 21C10.4833 21 9.76317 19.728 10.363 18.7282L13.7138 13.1437C14.2964 12.1727 15.7036 12.1727 16.2862 13.1437Z"
			fill="currentColor"
		/>
	</svg>
);

export const Whiteboard24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.teal', '#00A3BF');
	const label = props.label || '';
	if (fg('platform-visual-refresh-icons')) {
		return (
			<IconTile
				key="whiteboard"
				label={label}
				appearance="tealBold"
				shape="square"
				size="24"
				icon={WhiteboardIcon}
			/>
		);
	} else {
		return <Icon glyph={whiteboard24Svg} {...props} primaryColor={primaryColor} label={label} />;
	}
};
