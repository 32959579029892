import React from 'react';

import Page16Icon from '@atlaskit/icon-object/glyph/page/16';
import Page24Icon from '@atlaskit/icon-object/glyph/page/24';
import Blog16Icon from '@atlaskit/icon-object/glyph/blog/16';
import Blog24Icon from '@atlaskit/icon-object/glyph/blog/24';
import Calendar16Icon from '@atlaskit/icon-object/glyph/calendar/16';
import Calendar24Icon from '@atlaskit/icon-object/glyph/calendar/24';
import Question16Icon from '@atlaskit/icon-object/glyph/question/16';
import Question24Icon from '@atlaskit/icon-object/glyph/question/24';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';

import { Comment16Icon } from './images/Comment16Icon';
import { Comment24Icon } from './images/Comment24Icon';
import { Whiteboard16Icon } from './images/Whiteboard16Icon';
import { Whiteboard24Icon } from './images/Whiteboard24Icon';
import { Embed16Icon } from './images/Embed16Icon';
import { Embed24Icon } from './images/Embed24Icon';
import { Database24Icon } from './images/Database24Icon';
import { Database16Icon } from './images/Database16Icon';
import { Space16Icon } from './images/Space16Icon';
import { SmartLinkEmbed16Icon } from './images/SmartLinkEmbed16Icon';
import { SmartLinkEmbed24Icon } from './images/SmartLinkEmbed24Icon';
import { Folder20Icon } from './images/Folder20Icon';
import { Folder24Icon } from './images/Folder24Icon';
import { Import16Icon } from './images/Import16Icon';
import { Import24Icon } from './images/Import24Icon';

export const sizes = ['small', 'medium'];

const typeNames = {
	page: 'page',
	blogpost: 'blogpost',
	whiteboard: 'whiteboard',
	database: 'database',
	embed: 'embed',
	calendar: 'com.atlassian.confluence.extra.team-calendars:calendar-content-type',
	calendarView: 'com.atlassian.confluence.extra.team-calendars:space-calendars-view-content-type',
	comment: 'comment',
	question: 'ac:com.atlassian.confluence.plugins.confluence-questions:question',
	space: 'space',
	folder: 'folder',
	smartLinkEmbed: 'smartLinkEmbed',
	import: 'import',
};

export const types = {
	[typeNames.page]: [Page16Icon, Page24Icon],
	[typeNames.blogpost]: [Blog16Icon, Blog24Icon],
	[typeNames.whiteboard]: [Whiteboard16Icon, Whiteboard24Icon],
	[typeNames.database]: [Database16Icon, Database24Icon],
	[typeNames.embed]: [Embed16Icon, Embed24Icon],
	[typeNames.calendar]: [Calendar16Icon, Calendar24Icon],
	[typeNames.calendarView]: [Calendar16Icon, Calendar24Icon],
	//TODO: Remove the custom Icons once atlaskit adds the CommentIcons
	// https://ecosystem.atlassian.net/browse/AK-6057
	[typeNames.comment]: [Comment16Icon, Comment24Icon],
	[typeNames.question]: [Question16Icon, Question24Icon],
	[typeNames.space]: [Space16Icon],
	[typeNames.smartLinkEmbed]: [SmartLinkEmbed16Icon, SmartLinkEmbed24Icon],
	// the small folder icon is 20x20
	// once the next version of design system is released this icon can be imported from platform, and all small icons will be this size
	[typeNames.folder]: [Folder20Icon, Folder24Icon],
	[typeNames.import]: [Import16Icon, Import24Icon],
};

/**
 * @deprecated
 * Use ContentIcon instead. It uses the latest iconography and handles emoji.
 * this is used in the create + menus for the background
 **/
export const LegacyContentIcon = ({
	label,
	primaryColor, // can override default icon color
	size = 'medium',
	type = typeNames.page,
}: {
	label: string;
	primaryColor?: IconColor;
	// Not every icon supports a higher size
	size?: 'small' | 'medium';
	type?: string;
}) => {
	let testId = `content-icon-${size}`;

	const getIcon = () => {
		let contentType = type ? type.toLowerCase() : '';

		// embed v1 and embed v2 pass in the same content type value but use different icons
		if (contentType === typeNames.embed) {
			contentType = typeNames.smartLinkEmbed;
		}

		const icons = types[contentType] || types[typeNames.page];
		testId = types[contentType] ? testId.concat(`-${contentType}`) : testId.concat(`-page`);
		let sizeIndex = sizes.indexOf(size);
		sizeIndex = sizeIndex > 0 ? sizeIndex : 0;
		return icons[sizeIndex];
	};

	return React.createElement(getIcon(), {
		size,
		label,
		primaryColor,
		testId,
	});
};
